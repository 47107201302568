<template>
  <div class="wordcontent">
    <studyHeader :path="backPath"/>
    <div class="learn">
      <div class="practice" @click="goStudy">
        <img src="../../../assets/images/student/practice.png" alt="">
        <span class="text">开始学习</span>
      </div>
      <div v-for="(data, index) in wordList" :key="index" class="word" name="word" :class="'cachet_' + index % 5">{{ data.nr }}</div>
    </div>
  </div>
</template>
<script>
import { getWords, getMyBreakpoint } from '@/api/student'
export default {
  components:{
    studyHeader:(resolve) => {
      require(["@/components/student/studyHeader"], resolve)
    }
  },
  data(){
    return {
      wordList: [],
      words: [], // Dom
      backPath: '',
      notes: '' // 学习记录进度
    }
  },
  mounted(){
    if (this.$route.query.source === 'studyway') {
      // 学习之旅
      this.backPath = '/units?type=' + this.$route.query.type + '&bbid=' + this.$route.query.bbid + '&zid=' + this.$route.query.zid+ '&bbmc=' +this.$route.query.bbmc
      getWords({ dyid: Number(this.$route.query.dyid), xsid: this.$store.state.userInfo.yhid }).then(res => {
        if (res.success) {
          if (res.result.list.length) {
            this.wordList = res.result.list
            let jxInfo = JSON.parse(sessionStorage.getItem('jxInfo'))
            jxInfo.dcsl = res.result.dcsl
            jxInfo.zwsl = Number(res.result.dcsl - this.wordList.length)
            sessionStorage.setItem('jxInfo', JSON.stringify(jxInfo))
            // 获取单词dom
            this.$nextTick(() =>{
              this.words = document.getElementsByName('word')
              this.upsetRandom()
            })
          }
        } else {
          this.$message.error(res.resultDesc)
        }
      })
      getMyBreakpoint({ dyid: ~~this.$route.query.dyid, xsid: this.$store.state.userInfo.yhid}).then(res => {
        let notes = res.resultDesc === 'SUCCESS' ? '' : res.resultDesc
        this.notes = JSON.parse(notes)
      })
    } else if (this.$route.query.source === 'wrong') {
      // 错题本
      this.backPath = '/wordbook/question'
      this.wordList = JSON.parse(sessionStorage.getItem('words'))
      this.$nextTick(() =>{
        this.words = document.getElementsByName('word')
        this.upsetRandom()
      })
    } else if (this.$route.query.source === 'collect') {
      // 我的收藏
      this.backPath = '/wordbook/collection'
      this.wordList = JSON.parse(sessionStorage.getItem('words'))
      this.$nextTick(() =>{
        this.words = document.getElementsByName('word')
        this.upsetRandom()
      })
    }
  },
  methods:{
    GetRandomNum(Min,Max) {
      var Range = Max - Min
      var Rand = Math.random()
      return(Min + Math.round(Rand * Range))
    },
    // 随机分布
    upsetRandom() {
      this.words.forEach(ele => {
        ele.style.left = this.GetRandomNum(0, window.innerWidth - ele.offsetWidth) + 'px'
        ele.style.top = this.GetRandomNum(80, window.innerHeight - ele.offsetHeight - 250) + 'px'
        ele.style.transform = 'rotate(' + this.GetRandomNum(-15, 15) + 'deg)'
      })
    },
    // 开始答题
    goStudy(){
      if (this.wordList.length) {
        if (this.$route.query.source === 'studyway') {
          sessionStorage.setItem('words', JSON.stringify(this.wordList))
          this.$router.replace({ name: (this.notes && this.notes.progress) ? 'testpage' : 'study', query: { type: this.$route.query.type, bbid: this.$route.query.bbid, zid: this.$route.query.zid, dyid: this.$route.query.dyid, source: this.$route.query.source,bbmc:this.$route.query.bbmc } })
        } else {
          this.$router.replace({ name: 'study', query: { source: this.$route.query.source } })
        }
      } else {
        this.$message.warning('暂无单词')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.learn{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: url('../../../assets/images/student/learnwords.png') no-repeat;
  background-size: 100% 100%;
  .practice{
    width: 13rem;
    height: 4rem;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
    cursor: pointer;
    img{
      display: block;
      width: 100%;
      height: 100%;
    }
    .text{
      position: absolute;
      top: 0;
      text-align: center;
      color: #fff;
      height: 100%;
      width: 100%;
      font-size: 28px;
      line-height: 3.5rem;
    }
  }
  .word{
      position: absolute;
      top: calc(100% - 130px);
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 28px;
      padding: 5px 20px 10px;
      transition: 2s;
      color: #fff;
      border-radius: 25px;
      cursor: pointer;
      box-shadow: 0px 0px 8px #888888;
      backface-visibility: hidden;
    }
    .word:active{
      z-index: 999;
    }
  .cachet_0{
    background: url('../../../assets/images/student/btn1.png') no-repeat;
    background-size: 139% 279%;
    background-position: -10px -10px;
  }
  .cachet_1{
    background: url('../../../assets/images/student/btn2.png') no-repeat;
    background-size: 139% 279%;
    background-position: -10px -10px;
  }
  .cachet_2{
    background: url('../../../assets/images/student/btn3.png') no-repeat;
    background-size: 139% 279%;
    background-position: -10px -10px;
  }
  .cachet_3{
    background: url('../../../assets/images/student/btn4.png') no-repeat;
    background-size: 139% 279%;
    background-position: -10px -10px;
  }
  .cachet_4{
    background: url('../../../assets/images/student/btn5.png') no-repeat;
    background-size: 139% 279%;
    background-position: -10px -10px;
  }
}
</style>